angular.module('llax')
    .controller('showImageController', ['$rootScope', '$scope', '$document', '$modalInstance', 'CheckURLRessource', '$http', '$translate', '$filter', '$parse', 'growl', 'FileUploader', 'filePreviewData',
        function($rootScope, $scope,$document, $modalInstance, CheckURLRessource, $http, $translate, $filter, $parse, growl, FileUploader, filePreviewData) {
            $scope.filePreviewData = filePreviewData;
            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
                $rootScope.additionalModalOpen = false;
                $document.off('keydown', keyPressed);
            };

            function keyPressed($event) {
                if ($event.keyCode == 27) {
                    $event.preventDefault();
                    $scope.cancel();
                }
            }
            $document.on('keydown', keyPressed);

        }]);

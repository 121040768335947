angular.module('llax.services')
    .service('AssetFoldersService', function($log, $rootScope, $q, AssetFoldersResource, FileUploader) {

        this.LINK_TYPE_FOLDER = 'FOLDER';
        this.LINK_TYPE_ASSET = 'ASSET';

        this.EVENT_UPDATED = 'assetFolders.updated';
        this.EVENT_CHILDS_UPDATED = 'assetFolders.childsUpdated';

        this.getDefaultUploadUrl = function() {
            var defaultFolderPath = '';
            return lax_rest_url('assetFolders/_uploadFile/' + defaultFolderPath);
        };

        this.getUploadUrl = function(path) {
            return lax_rest_url('assetFolders/_uploadFile/' + path);
        };

        this.getUploader = function (path, config) {

            return new FileUploader({
                url: this.getUploadUrl(path),
                multiple: config.multiple || false,
                formData: config.formData || [],
                autoUpload: config.autoUpload || false,
                reset: config.reset || false,
                removeAfterUpload: config.removeAfterUpload || false,
                onCompleteAll: function() {
                    if (_.isFunction(config.onCompleteAll)) {
                        config.onCompleteAll(this);
                    }
                },
                onAfterAddingFile: function(fileItem) {
                    if (config.useFilename) {
                        fileItem.alias = fileItem.file.name;
                    }

                    if (_.isFunction(config.onAfterAddingFile)) {
                        config.onAfterAddingFile(fileItem);
                    }
                },
                onErrorItem: function(item, response, status, headers) {
                    if (_.isFunction(config.onErrorItem)) {
                        config.onErrorItem(item, response, status, headers);
                    }
                }
            });
        };

        this.getPublicAssetUrlAsync = function(linkedAsset, linkedAssetPath) {

            var publicAssetUrl = linkedAsset.publicAssetUrl;
            var deferred = $q.defer();

            if (_.isEmpty(publicAssetUrl)) {
                AssetFoldersResource.convertToPublicAsset({
                    path: linkedAssetPath
                },
                {},
                function(response) {
                    deferred.resolve(response);
                }, function(error) {
                    deferred.reject(error);
                });
            } else {
                deferred.resolve(linkedAsset);
            }

            return deferred.promise;
        };

        this.handleDropEventAsync = function(droppedItems, droppedOnItem, contextPathWhenDragged, onItemMoved) {

            var _this = this;
            var deferred = $q.defer();

            var destinationPath = '';
            if (_.isNil(droppedOnItem)) { // If not dropped on root, i.e. there is no dropped-on item.
                destinationPath = contextPathWhenDragged;
            } else {
                destinationPath = this.prependCurrentPath(contextPathWhenDragged, droppedOnItem.name);
            }

            var moveRequests = _.map(droppedItems, function(item) {
                var sourcePath = item.fullPath;
                var itemType = item.item.linkType;

                var request = _this.moveItemAsync(itemType, sourcePath, destinationPath)
                    .then(function() {
                        onItemMoved(sourcePath, destinationPath);
                    });

                return request;
            });

            $q.all(moveRequests)
                .then(function() {
                    deferred.resolve();
                }).catch(function(error){
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        this.sortAssetItems = function(items) {
            var _this = this;
            return items.sort(function(a, b) {
                try {
                    if (a.linkType === b.linkType) {
                        return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
                    } else {
                        // Folders before files
                        return a.linkType === _this.LINK_TYPE_FOLDER ? -1 : 1;
                    }
                } catch(e) {
                    $log.error(e);
                    return 0;
                }
            });
        };

        this.decodeFilename = function(filename) {
            return decodeURIComponent(filename.replace(/\+/g, ' '));
        };

        this.getParentFolderPath = function(path) {
            if (_.isEmpty(path)) {
                return path;
            } else {
                var pathComponents = path.split('/');
                pathComponents.pop();
                return pathComponents.join('/');
            }
        };

        this.moveItemAsync = function(linkType, sourcePath, destinationPath) {

            var deferred = $q.defer();

            // Ignore if we are moving the same item
            if (sourcePath === destinationPath) {
                deferred.reject('moveItemAsync: Source is the same as the destination');
                return deferred.promise;
            }

            if (linkType === this.LINK_TYPE_FOLDER) {
                AssetFoldersResource.moveFolder({
                    path: sourcePath
                }, {
                    path: destinationPath
                }, function(response) {
                    deferred.resolve();
                }, function(error) {
                    deferred.reject(error);
                });
            } else if (linkType === this.LINK_TYPE_ASSET) {
                AssetFoldersResource.moveAsset({
                    path: sourcePath
                }, {
                    path: destinationPath
                }, function(response) {
                    deferred.resolve();
                }, function(error) {
                    deferred.reject(error);
                });
            }

            return deferred.promise;
        };

        this.prepareDragAndDropEvent = function($event, draggedItems, contextPathWhenDragged) {

            var _this = this;
            var eventDataItem = _.map(draggedItems, function(item) {
                var itemFullPath =
                    _this.prependCurrentPath(contextPathWhenDragged, item.name);

                return {
                    fullPath: itemFullPath,
                    item: item
                };
            });

            $event.originalEvent.dataTransfer.setData('application/json', JSON.stringify(eventDataItem));
        };

        this.prependCurrentPath = function(currentPath, path) {
            if (_.isEmpty(currentPath)) {
                return path;
            } else {
                return currentPath + '/' + path;
            }
        };

        this.getFileIcon = function(fileName) {
            fileName = fileName.toLowerCase();
            if (fileName.endsWith('pdf')) {
                return 'syncons-file-pdf';
            } else if (fileName.endsWith('csv') || fileName.endsWith('xlsx') || fileName.endsWith('xls')) {
                return 'syncons-file-excel';
            } else if (fileName.endsWith('ppt') || fileName.endsWith('pptx')) {
                return 'syncons-file-powerpoint';
            } else if (fileName.endsWith('json')) {
                return 'syncons-file-code';
            } else if (fileName.endsWith('docx') || fileName.endsWith('doc')) {
                return 'syncons-file-word';
            } else if (fileName.endsWith('zip') || fileName.endsWith('rar') || fileName.endsWith('tar.gz')) {
                return 'syncons-file-zip';
            } else if (fileName.endsWith('txt')) {
                return 'syncons-file-text';
            } else {
                return 'syncons-file';
            }
        };

        this.getFileIconByMimeType = function(mimeType) {
            switch(mimeType) {
                case 'application/pdf':
                    return 'syncons-file-pdf';

                case 'text/csv':
                case 'application/vnd.ms-excel':
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                case 'application/vnd.ms-excel.sheet.macroenabled':
                    return 'syncons-file-excel';

                case 'application/vnd.ms-powerpoint':
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                    return 'syncons-file-powerpoint';

                case 'application/json':
                    return 'syncons-file-code';

                case 'application/msword':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    return 'syncons-file-word';

                case 'application/zip':
                    return 'syncons-file-zip';

                case 'text/plain':
                    return 'syncons-file-text';

                default:
                    return 'syncons-file';
            }
        };

    });
